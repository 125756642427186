<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as PRODUCT_CATEGORY_TYPES } from '../store'

export default {
  name: 'TypeOfSaleMixin',
  created () {
    if (!this.typeOfSaleChoices || (this.typeOfSaleChoices && this.typeOfSaleChoices.length === 0)) {
      this.getTypeOfSaleChoices()
    }
  },
  computed: {
    ...mapGetters({
      typeOfSaleChoices: PRODUCT_CATEGORY_TYPES.GENERIC.product.productcategory.LIST.GETTERS.typeOfSaleChoices
    }),
    typeOfSaleListOptions () {
      return this.prepareListForSelect(this.typeOfSaleChoices, '0', ['1'])
    }
  },
  methods: {
    ...mapActions({
      getTypeOfSaleChoices: PRODUCT_CATEGORY_TYPES.GENERIC.product.productcategory.LIST.ACTIONS.getTypeofsalechoices
    }),
    selectTypeOfSaleChoice (item) {
      this.$set(this.form, 'type_of_sale', item.value)
      this.$set(this.form, 'type_of_sale_name', item.name)
    },
    clearTypeOfSaleChoice () {
      this.$set(this.form, 'type_of_sale', null)
      this.$set(this.form, 'type_of_sale_name', null)
    }
  }
}
</script>
