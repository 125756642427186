<template>
  <panel :title="title" noButton="true" v-has-perms="rolePerm">
    <div>
      <b-form>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.code"
            :external-errors="errors['code']"
            :label-form="'Code' | translate"
            class-form="col-6 mb-2"
            type="text"
            :value="form.code"
            :disabled="!formEditable"
            @change="$set(form, 'code', $event)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.name"
            :external-errors="errors['name']"
            :label-form="'Name' | translate"
            class-form="col-6 mb-2"
            type="text"
            :value="form.name"
            :disabled="!formEditable"
            @change="$set(form, 'name', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.description"
            :external-errors="errors['description']"
            :label-form="'Description' | translate"
            class-form="col-6 mb-2"
            type="text"
            :value="form.description"
            :disabled="!formEditable"
            @change="$set(form, 'description', $event)"
          />
          <form-thux-horizontal-select
            :validator="$v.form.type_of_sale"
            :external-errors="errors['type_of_sale']"
            :label-form="'Type of sale' | translate"
            class-form="col-6 mb-2 mt-2"
            labelColsSm="2"
            labelColsLg="2"
            :disabled="!formEditable"
            label-search="name"
            :customize-option="true"
            :value="form.type_of_sale ? { value: form.type_of_sale, name: form.type_of_sale_name } : null"
            :options="typeOfSaleListOptions"
            @select="selectTypeOfSaleChoice"
            @clear="clearTypeOfSaleChoice"
          />
        </b-form-row>
      </b-form>
      <div class="mt-3 f-w-600 d-flex justify-content-end">
        <div class="mt-3 f-w-600 d-flex justify-content-end">
          <b-button
            class="mr-3"
            variant="default"
            @click.prevent="formEditable ? cancel() : closeForm()">
            {{ formEditable ? 'Cancel' : 'Close' | translate }}
          </b-button>
          <b-button
            variant="primary"
            v-has-perms="editRolePerm"
            @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
            :disabled="$v.form.$invalid">
            {{ formEditable ? 'Save' : 'Edit' | translate }}
          </b-button>
        </div>
      </div>
    </div>
  </panel>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { TYPES as PRODUCT_CATEGORY_TYPES } from './store'

import ThuxDetailPanelMixin from '@/components/thux-detail-panel/ThuxDetailPanelMixin'
import TypeOfSaleChoicesMixin from './mixins/TypeOfSaleChoicesMixin.vue'

export default {
  name: 'ProductCategoryEdit',
  mixins: [ThuxDetailPanelMixin, TypeOfSaleChoicesMixin],
  data () {
    return {
      pageName: 'Product category',
      rolePerm: 'product_productcategory_retrieve',
      editRolePerm: 'product_productcategory_update'
    }
  },
  computed: {
    ...mapGetters({
      ...PRODUCT_CATEGORY_TYPES.GENERIC.product.productcategory.DETAIL.GETTERS
    })
  },
  components: { },
  methods: {
    ...mapActions({
      ...PRODUCT_CATEGORY_TYPES.GENERIC.product.productcategory.DETAIL.ACTIONS
    })
  },
  validations: {
    form: {
      code: { required },
      name: { required },
      description: { },
      type_of_sale: { }
    }
  }
}
</script>
