<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as PRODUCT_CATEGORY_TYPES } from './store'
import { ROUTES as PRODUCT_CATEGORY_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import ProductCategoryTable from './ProductCategoryTable'
import ProductCategoryCommonFilters from './ProductCategoryCommonFilters'
import ProductCategoryEdit from './ProductCategoryEdit.vue'

export default {
  name: 'ProductCategoryList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': ProductCategoryTable,
    'common-filters': ProductCategoryCommonFilters,
    'component-edit': ProductCategoryEdit,
    'component-detail': ProductCategoryEdit
  },
  data () {
    return {
      PRODUCT_CATEGORY_ROUTES,
      title: this.$t('Product category'),
      searchFields: [
        { key: 'filter__code__icontains', placeholder: this.$t('Code'), type: 'text', col: 6 },
        { key: 'filter__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 6 },
        { key: 'filter__type_of_sale__icontains', placeholder: this.$t('Type of sale'), type: 'text', col: 6 }
      ],
      rolePerm: ['product_productcategory_list'],
      actionEnablePermission: ['product_productcategory_enable'],
      actionDisablePermission: ['product_productcategory_disable']
    }
  },
  computed: {
    ...mapGetters({
      ...PRODUCT_CATEGORY_TYPES.GENERIC.product.productcategory.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.PRODUCT_CATEGORY_ROUTES.PRODUCT_CATEGORY_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('product_productcategory_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: PRODUCT_CATEGORY_TYPES.GENERIC.product.productcategory.LIST.MUTATIONS.setSelectedList,
      setSelectAll: PRODUCT_CATEGORY_TYPES.GENERIC.product.productcategory.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...PRODUCT_CATEGORY_TYPES.GENERIC.product.productcategory.LIST.ACTIONS
    })
  }
}
</script>
